<template>
  <div class="container">
  </div>
</template>

<script>

export default {
  name: "Renters",
  created() {
    this.$router.push("/renters/apartments");
  }
};
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
